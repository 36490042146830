<template>
  <div :class="$style.root">
    <div :class="$style.header">{{ get(field, 'label') }}</div>
    <div :class="$style.hint" v-if="get(field, 'hint')">{{ get(field, 'hint') }}</div>
    <VRow @input="onChange">
      <VCol>
        <VTextField
          v-model="fields.fio"
          label="ФИО Ответственного"
          outlined
          dense
          hideDetails="auto"
        />
      </VCol>
      <VCol>
        <VTextField
          v-model="fields.email"
          label="Email для рассылки"
          :rules="rules.email"
          outlined
          dense
          hideDetails="auto"
        />
      </VCol>
      <VCol>
        <VTextField
          v-model="fields.phone"
          label="Телефон"
          :rules="rules.phone"
          v-mask="'+7 (###) ### ## ##'"
          outlined
          dense
          hideDetails="auto"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import {debounce, forEach, get, throttle} from 'lodash-es';

export default {
  name: 'ActivityInfoParticipation',
  props: {
    label: { type: String },
    value: { type: Object },
    field: { type: Object },
  },
  data() {
    return {
      rules: {
        phone: [
          (value) => !value || /[+7] \(\d{3}\) \d{3} \d{2} \d{2}/.test(value) || 'Укажите корректный телефон',
        ],
        email: [
          (value) => !value || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value) || 'Укажите корректный email',
        ]
      },
      fields: {
        fio: '',
        email: '',
        phone: '',
      }
    }
  },
  methods: {
    throttle,
    debounce,
    get,
    onChange: throttle(function() {
      this.$emit('input', this.fields);
    }, 250),
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        forEach(value, (value, key) => {
          this.fields[key] = value;
        });
      },
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-input__slot) {
    align-items: flex-start;
  }
}
.header {
  font-weight: 500;
  font-size: 18px;
  background: #fff7e4;
  border-bottom: 1px solid #e6cfbe;
  padding: 5px 16px;
  margin: 0 -16px;
}
.hint {
  margin: 16px 0;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;

  color: #ff5252;
}
.title {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}
</style>
