import api from './api';
import {includes, some} from 'lodash-es';
const START = 'START';
const END = 'END';

const SET_DETAIL = 'SET_DETAIL';
const SET_LIST = 'SET_LIST';
const SET_TIMELINE = 'SET_TIMELINE';
const SET_EXTRA = 'SET_EXTRA';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItem: false,
      fetchList: false,
      fetchTimeline: false,
      fetchExtra: false,
      deleteAccount: false,
      recoverAccount: false,
      updateExtra: false,
      changeAuthor: false,
    },
    detail: {},
    list: {},
    timeline: [],
    extra: {},
  },
  getters: {
    detail: (state) => {
      return state.detail;
    },
    list: (state) => {
      return state.list;
    },
    timeline: (state) => {
      return state.timeline;
    },
    extra: (state) => {
      return state.extra;
    },
    hasType(state) {
      return (type) => type === state.detail.type;
    },
    pending: (state) => {
      return includes(state.pending, true);
    },
    loading: (state, keys) => {
      return some(keys, (key) => state.pending[key])
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_DETAIL]: (state, detail) => {
      state.detail = detail;
    },
    [SET_LIST]: (state, list) => {
      state.list = list;
    },
    [SET_EXTRA]: (state, extra) => {
      state.extra = extra;
    },
    [SET_TIMELINE]: (state, timeline) => {
      state.timeline = timeline;
    },
  },
  actions: {
    getAccountDetail: ({ commit }, { id } ) => {
      commit(START, 'fetchItem');
      return api.getAccountDetail({ id }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'fetchItem');
        return detail;
      }).catch(() => {
        commit(END, 'fetchItem');
      });
    },
    getAccountList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchList');
      return api.getAccountList({ page, size, filter }).then(list => {
        commit(SET_LIST, list);
        commit(END, 'fetchList');
        return list;
      }).catch(() => {
        commit(END, 'fetchList');
      });
    },
    getAccountExtra: ({ commit }, { accountId }) => {
      commit(START, 'fetchExtra');
      return api.getAccountExtra({ accountId }).then(extra => {
        commit(SET_EXTRA, extra);
        commit(END, 'fetchExtra');
        return extra;
      }).catch(() => {
        commit(END, 'fetchExtra');
      });
    },
    updateAccountExtra: ({ commit }, { item }) => {
      commit(START, 'updateExtra');
      return api.updateAccountExtra({ item }).then(extra => {
        commit(SET_EXTRA, extra);
        commit(END, 'updateExtra');
        return extra;
      }).catch(() => {
        commit(END, 'updateExtra');
      });
    },
    fetchTimeline: ({ commit }, { id }) => {
      commit(START, 'fetchTimeline');
      return api.fetchTimeline({ id }).then(timeline => {
        commit(SET_TIMELINE, timeline);
        commit(END, 'fetchTimeline');
        return timeline;
      }).catch(() => {
        commit(END, 'fetchTimeline');
      });
    },
    deleteAccount: ({ commit }, { id }) => {
      commit(START, 'deleteAccount');
      return api.deleteAccount({ id }).then((detail) => {
        commit(END, 'deleteAccount');
        return detail;
      }).catch(() => {
        commit(END, 'deleteAccount');
      });
    },
    recoverAccount: ({ commit }, { id }) => {
      commit(START, 'recoverAccount');
      return api.recoverAccount({ id }).then(detail => {
        commit(END, 'recoverAccount');
        return detail;
      }).catch(() => {
        commit(END, 'recoverAccount');
      });
    },
    changeAuthor: ({ commit }, { id, authorId  }) => {
      commit(START, 'changeAuthor');
      return api.changeAuthor({ id, authorId }).then(detail => {
        commit(END, 'changeAuthor');
        return detail;
      }).catch(() => {
        commit(END, 'changeAuthor');
      });
    },
  }
}
