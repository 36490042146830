var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',_vm._l((_vm.fields),function({ label, key, rules }){return _c('VCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow(key)),expression:"isShow(key)"}],key:key,attrs:{"cols":"12","sm":"6","md":"4"}},[(key === 'inn')?_c('InputInnDadata',{attrs:{"label":label,"value":_vm.value[key],"rules":(() => _vm.customValidationRules(rules, key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"disabled":(() => _vm.disabledFields(key))(),"can-edit":_vm.canEdit,"outlined":"","dense":"","hide-details":"auto"},on:{"input":val => _vm.onChange(val, key),"selected":_vm.updateInnData}}):(key === 'country' && _vm.canEdit)?_c('InputCountryDadata',{attrs:{"label":label,"value":_vm.value[key],"disabled":(() => _vm.disabledFields(key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"rules":(() => _vm.customValidationRules(rules, key))(),"save-user-value":false},on:{"input":val => _vm.onChange(val, key),"select":({ country_iso_code }) => {
            const payload = _vm.value;
            _vm.set(payload, 'countryIsoCode', country_iso_code);
            _vm.$emit('input', payload)
          }}}):(key === 'region' && _vm.canEdit)?_c('InputRegionDadata',{attrs:{"label":label,"value":_vm.value[key],"locations":[_vm.pick(_vm.locations, 'country_iso_code')],"disabled":(() => _vm.disabledFields(key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"rules":(() => _vm.customValidationRules(rules, key))(),"save-user-value":_vm.isForeignCompany},on:{"input":val => _vm.onChange(val, key),"select":({ region_iso_code }) => {
            const payload = _vm.value;
            _vm.set(payload, 'regionIsoCode', region_iso_code);
            _vm.$emit('input', payload)
          }}}):(key === 'city' && _vm.canEdit)?_c('InputCityDadata',{attrs:{"label":label,"value":_vm.value[key],"locations":[_vm.pick(_vm.locations, 'country_iso_code', 'region_iso_code')],"disabled":(() => _vm.disabledFields(key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"rules":(() => _vm.customValidationRules(rules, key))(),"save-user-value":_vm.isForeignCompany},on:{"input":val => _vm.onChange(val, key)}}):(key === 'street' && _vm.canEdit)?_c('InputStreetDadata',{attrs:{"label":label,"value":_vm.value[key],"locations":[_vm.pick(_vm.locations, 'country_iso_code', 'region_iso_code')],"disabled":(() => _vm.disabledFields(key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"rules":(() => _vm.customValidationRules(rules, key))(),"save-user-value":_vm.isForeignCompany},on:{"input":val => _vm.onChange(val, key)}}):(_vm.isShow(key))?_c('AccountApplicationField',{attrs:{"value":_vm.value[key],"label":label,"can-edit":_vm.canEdit,"rules":(() => _vm.customValidationRules(rules, key))(),"readonly":_vm.notEditableFields.includes(key) && _vm.isEdit,"disabled":(() => _vm.disabledFields(key))()},on:{"input":val => _vm.onChange(val, key)}}):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }