<template>
  <div :class="$style.root">
    <VDataTable
      fixed-header
      height="calc(100vh - 245px)"
      hideDefaultHeader
      :headers="headers"
      :items="items"
      :loading="loading"
      :footer-props="footer"
      :options.sync="options"
      :server-items-length="count"
      :mobile-breakpoint="0"
      @click:row="({ id }) => onDetail(id)"
      @update:page="page => onPagination({ page })"
      @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
        <tr>
          <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
        </tr>
        <FilterRow :items="headers" @input="onPagination" />
        </thead>
      </template>
      <template v-slot:item.type="{ item: { type } }">
        <CompactCeil :text="get(organizationTypes[type], 'text', type)" />
      </template>
      <template v-slot:item.isActive="{ item: { isActive } }">
        <CompactCeil :text="isActive ? 'Активен' : 'Не активен'" />
      </template>
    </VDataTable>
  </div>
</template>

<script>

import {get, omitBy, isNull} from 'lodash-es';
import FilterRow from '@/components/inspect/FilterRow/FilterRow';
import CompactCeil from '@/components/inspect/ApplicationList/components/CompactCeil.vue';
import {ACCOUNT_APPLICATION_ORGANIZATION_TYPES} from '@/store/account-application/enums';

export default {
  name: 'AccountList',
  components: {
    CompactCeil,
    FilterRow,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    organizationTypes() {
      return ACCOUNT_APPLICATION_ORGANIZATION_TYPES;
    },
  },
  methods: {
    get,
    onDetail(id) {
      this.$router.push({ name: 'account/accountUserView', params: { user: id }});
    },
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: omitBy({ ...query, ...params }, isNull) });
    },
  }
}
</script>

<style module lang="scss">
.root {
  td {
    width: 500px;
    min-width: 130px;
    &:nth-child(1) { max-width: 50px; min-width: 50px; }
    &:nth-child(2) { max-width: 100px; }
    &:nth-child(3) { max-width: 105px; }
    &:nth-child(4) { max-width: 130px; }
    &:nth-child(5) { max-width: 100px; }
    &:nth-child(6) { max-width: 200px; }
    &:nth-child(7) { max-width: 200px; }
  }
}
</style>
