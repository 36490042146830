<template>
  <VMenu
    v-if="canEdit"
    v-model="menuOpen"
    :disabled="disabled || readonly"
    :min-width="inputWidth"
    :max-width="inputWidth"
    max-height="300"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <VTextField
        ref="inputRef"
        v-bind="attrs"
        v-on="on"
        :label="label"
        :value="value"
        @input="value => $emit('input', value)"
        :rules="rules"
        :readonly="readonly"
        :disabled="disabled"
        :loading="loading"
        outlined
        dense hide-details="auto"/>
    </template>
    <VList>
      <VListItemGroup
        v-model="selectedItem"
        @change="changeSelectedItem"
      >
        <VListItem v-for="{ title } in items" :key="title">
          {{ title }}
        </VListItem>
      </VListItemGroup>
    </VList>
  </VMenu>
  <StaticField
    v-else
    :label="label"
    :value="value"
  />
</template>
<script>
import {inn} from '@/api/dadata';
import {isEmpty, map, throttle, get} from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField.vue';

export default {
  name: 'InputInnDadata',
  components: { StaticField },
  props: {
    label: { type: String },
    rules: { type: Array, default: () => [] },
    value: { type: String },
    readonly: { type: Boolean },
    disabled: { type: Boolean },
    canEdit: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedItem: null,
      menuOpen: false,
      loading: false,
      inputWidth: undefined,
      suggestions: [],
      wasOpened: false, // Был ли открыт список (избавление от лишних запросов)
    }
  },
  computed: {
    items() {
      if (isEmpty(this.suggestions)) {
        if (this.value) return [{ title: this.value, value: this.value }];
        else return [];
      }
      return map(this.suggestions, (suggestion) => {
        return this.formatter(suggestion);
      });
    },
  },
  methods: {
    onSearch: throttle(function (query) {
      if (query && (query?.length === 10 || query?.length === 12)) {
        this.loading = true;
        inn(query).then(({ suggestions }) => {
          this.loading = false;
          this.$set(this, 'suggestions', suggestions);
        }).catch(() => this.loading = false);
      } else {
        this.$set(this, 'suggestions', []);
      }
    }, 500),
    changeSelectedItem(selectedIndex) {
      const newData = get(this.suggestions, selectedIndex);
      if (newData) this.$emit('selected', newData);
    },
    formatter(item) {
      return { title: get(item, 'value'), value: get(item, 'data.inn') };
    },
  },
  watch: {
    menuOpen : {
      handler: function () {
        if (this.menuOpen) {
          this.wasOpened = true;
          this.onSearch(this.value)
        }
        this.inputWidth = get(this.$refs, 'inputRef.$el.offsetWidth');
      }
    },
    value: {
      handler: function() {
        if (this.wasOpened) this.onSearch(this.value)
      }
    }
  }
};
</script>
