<template>
  <div>
    <template v-if="type === 'file'">
      <FileFieldMultiple
        class="d-inline-block"
        :multiple="multiple"
        showCreateDate
        :required="required"
        :readonly="readonly"
        :disabled="disabled"
        :value="value?.map(value => ({ value }))"
        :filled="!!value?.length"
        :entity="entity"
        :section="section"
        @input="(values) => $emit('input', values.map(({ value }) => value))"
      />
      <div class="mt-2" v-if="canEdit">{{ fileFormatsLabel }}</div>
    </template>
    <template v-if="type === 'select'">
      <VSelect
        v-if="canEdit"
        :label="label"
        :value="value"
        @input="value => $emit('input', value)"
        :items="items"
        :rules="rules"
        :disabled="disabled"
        :item-text="itemText"
        :item-value="itemValue"
        outlined dense hide-details="auto" />
      <StaticField
        v-else
        :label="label"
        :value="selectStaticValue"/>
    </template>
    <template v-if="type === 'textarea'">
      <VTextarea
        v-if="canEdit"
        :value="value"
        @input="value => $emit('input', value)"
        :label="label"
        :rules="rules"
        outlined dense auto-grow rows="2" hideDetails="auto"/>
      <StaticField
        v-else
        :value="value"
        :label="label"/>
    </template>
    <template v-else-if="type === 'text'">
      <VTextField
        v-if="canEdit"
        :label="label"
        :value="value"
        @input="value => $emit('input', value)"
        :rules="rules"
        :disabled="disabled"
        :readonly="readonly"
        v-mask="mask"
        outlined dense hide-details="auto"/>
      <StaticField
        v-else
        :label="label"
        :value="value"/>
    </template>
  </div>
</template>
<script>

import StaticField from '@/components/general/StaticField/StaticField.vue';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple.vue';
import {find, get} from 'lodash-es';

export default {
  name: 'AccountApplicationField',
  components: { FileFieldMultiple, StaticField },
  props: {
    label: { type: String },
    value: { type: [String, Array] },

    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean },
    disabled: { type: Boolean },
    canEdit: { type: Boolean, default: true },

    entity: { type: String },
    fileFormatsLabel: { type: String, default: 'Доступные форматы: pdf, tif, jpeg, jpg, png. Размер не более 20МБ. Не более 1 файла' },
    section: { type: String },
    multiple: { type: Boolean, default: false, },
    required: { type: Boolean, default: false },

    mask: { type: String },

    rows: { type: String },

    items: { type: Array },
    itemText: { type: String },
    itemValue: { type: String },

    type: { type: String, default: 'text', validator: (value) => {
        return ['text', 'file', 'textarea', 'select'].includes(value)
      }}
  },
  data() {
    return {

    }
  },
  computed: {
    selectStaticValue() {
      return get(find(this.items, (item) => get(item, this.itemValue) === this.value), this.itemText) || this.value;
    }
  },
  methods: {

  },
  watch: {

  }
};
</script>
