import {get, map, toString, toNumber, compact} from 'lodash-es';

export const accountApplicationTimeLine = (result) => {
  return map(get(result, 'items'), (item) => {
    return {
      dateCreate: toString(get(item, 'dateCreate')),
      comment: toString(get(item, 'message')),
      prevStatus: {
        code:toString(get(item, 'prevStatus.code')),
        title: toString(get(item, 'prevStatus.title')),
      },
      newStatus: {
        code:toString(get(item, 'newStatus.code')),
        title: toString(get(item, 'newStatus.title')),
      },
      author: {
        id: get(item, 'author.id'),
        name: toString(get(item, 'author.name')),
        lastName: toString(get(item, 'author.lastName')),
        secondName: toString(get(item, 'author.secondName')),
        email: toString(get(item, 'author.email')),
        companyName: toString(get(item, 'author.companyName')),
        companyInn: toString(get(item, 'author.companyInn')),
        phone: toString(get(item, 'author.phone')),
        address: toString(get(item, 'author.address')),
        firstGenContact: toString(get(item, 'author.firstGenContact')),
        companyOgrn: toString(get(item, 'author.companyOgrn')),
      },
    }
  })
}

export const accountApplicationItem = (item) => {
  return {
    id: get(item, 'id'),
    type: toString(get(item, 'type')),
    status: toString(get(item, 'status')),
    commentToDelete: toString(get(item, 'commentToDelete')),
    dateCreate: toString(get(item, 'dateCreate')),
    availableActions: get(item, 'availableActions', []),
    author: {
      id: get(item, 'author.id'),
      name: toString(get(item, 'author.name')),
      lastName: toString(get(item, 'author.lastName')),
      secondName: toString(get(item, 'author.secondName')),
      email: toString(get(item, 'author.email')),
      companyName: toString(get(item, 'author.companyName')),
      companyInn: toString(get(item, 'author.companyInn')),
      phone: toString(get(item, 'author.phone')),
      address: toString(get(item, 'author.address')),
      firstGenContact: toString(get(item, 'author.firstGenContact')),
      companyOgrn: toString(get(item, 'author.companyOgrn')),
    },
    account: {
      id: get(item, 'account.id'),
      author: toString(get(item, 'account.author')),
      changeUser: toString(get(item, 'account.changeUser')),
      dateCreate: toString(get(item, 'account.dateCreate')),
      dateChange: toString(get(item, 'account.dateChange')),
      isActive: !!get(item, 'account.isActive'),
      xmlId: get(item, 'account.xmlId'),
      valuesVersionId: toNumber(get(item, 'account.valuesVersionId')),
      taxId: get(item, 'account.taxId', ''),
      codeKsc: toString(get(item, 'account.codeKsc')),
      type: toString(get(item, 'account.type')),
      inn: toString(get(item, 'account.inn')),
      kpp: toString(get(item, 'account.kpp')),
      passportNumber: toString(get(item, 'account.passportNumber')),
      passportDate: toString(get(item, 'account.passportDate')),
      companyNameFull: toString(get(item, 'account.companyNameFull')),
      companyNameShort: toString(get(item, 'account.companyNameShort')),
      ogrn: toString(get(item, 'account.ogrn')),
      verificationLetter: compact([toString(get(item, 'account.verificationLetter', ''))]),
      companyCharter: compact([toString(get(item, 'account.companyCharter', ''))]),
      companyEgrul: compact([toString(get(item, 'account.companyEgrul', ''))]),
      companyElectronProtocol: compact([toString(get(item, 'account.companyElectronProtocol', ''))]),
      companyNalogCert: compact([toString(get(item, 'account.companyNalogCert', ''))]),
      companyOgrnCert: compact([toString(get(item, 'account.companyOgrnCert', ''))]),
      companyDirectorOrder: compact([toString(get(item, 'account.companyDirectorOrder', ''))]),
      address: {
        country: toString(get(item, 'account.address.country')),
        region: toString(get(item, 'account.address.region')),
        zipCode: toString(get(item, 'account.address.zipCode')),
        city: toString(get(item, 'account.address.city')),
        street: toString(get(item, 'account.address.street')),
        house: toString(get(item, 'account.address.house')),
        building: toString(get(item, 'account.address.building')),
        flat: toString(get(item, 'account.address.flat')),
        countryIsoCode: toString(get(item, 'account.address.countryIsoCode')),
        regionIsoCode: toString(get(item, 'account.address.regionIsoCode')),
      },
      contactPerson: {
        email: toString(get(item, 'account.contactPerson.email')),
        fio: toString(get(item, 'account.contactPerson.fio')),
        phone: toString(get(item, 'account.contactPerson.phone')),
      },
      organizationLeader: {
        email: toString(get(item, 'account.organizationLeader.email')),
        fio: toString(get(item, 'account.organizationLeader.fio')),
        phone: toString(get(item, 'account.organizationLeader.phone')),
        position: toString(get(item, 'account.organizationLeader.position')),
      },
    },
  }
}

export const accountApplicationDetail = (result) => {
  return {
    ...accountApplicationItem(get(result, 'item', {})),
  }
};

export const accountApplicationList = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => accountApplicationItem(item)),
  }
};
