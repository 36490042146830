<template>
  <VAutocomplete
      :label="label"
      :value="value"
      :search-input.sync="search"
      :items="options"
      :disabled="disabled"
      :readonly="readonly"
      :rules="rules"
      :loading="loading"
      @change="onChange"
      @blur="onBlur"
      outlined
      dense
      autocomplete="chrome-off"
      hideDetails="auto"
  />
</template>

<script>
import { get, map, throttle, isEmpty, find } from 'lodash-es';
import { country } from '@/api/dadata';

export default {
  name: 'InputCountryDadata',
  props: {
    label: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    rules: { type: Array, default: () => []},
    saveUserValue: { type: Boolean, default: true },
  },
  data() {
    return {
      loading: false,
      search: '',
      suggestions: [],
    }
  },
  computed: {
    options() {
      if (isEmpty(this.suggestions)) {
        if (this.value) return [this.value];
        else return [];
      }
      return map(this.suggestions, (suggestion) => {
        const value = get(suggestion, 'value') || get(suggestion, 'data.name');
        return {
          value,
          text: value,
          data: suggestion,
        };
      });
    }
  },
  methods: {
    onChange: function(value) {
      this.$emit('input', value);
      this.$emit('select', {
        country_iso_code: get(find(this.options, { value }), 'data.data.alfa2'),
      });
    },
    onSearch: throttle(function(query) {
      if (query) {
        this.loading = true;
        country(query).then(({ suggestions }) => {
          this.loading = false;
          this.$set(this, 'suggestions', suggestions);
        })
        .catch(() => this.loading = false);
      }
    }, 500),
    onBlur: function() {
      if (!this.search || this.saveUserValue) {
        this.$emit('input', this.search);
      }
    }
  },
  watch: {
    value: {
      handler: function() {
        this.suggestions = [];
      },
    },
    search: {
      handler: function(query) {
        if (query !== this.value) this.onSearch(query);
      },
    },
  }
}
</script>
