<template>
  <VRow>
    <VCol
      cols="12" sm="6" md="4"
      v-show="isShow(key)"
      v-for="{ label, key, rules } in fields" :key="key"
    >
      <InputInnDadata
        v-if="key === 'inn'"
        :label="label"
        :value="value[key]"
        @input="val => onChange(val, key)"
        :rules="(() => customValidationRules(rules, key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :disabled="(() => disabledFields(key))()"
        @selected="updateInnData"
        :can-edit="canEdit"
        outlined dense hide-details="auto"
      />
      <InputCountryDadata
        v-else-if="key === 'country' && canEdit"
        :label="label"
        :value="value[key]"
        @input="val => onChange(val, key)"
        @select="({ country_iso_code }) => {
              const payload = value;
              set(payload, 'countryIsoCode', country_iso_code);
              $emit('input', payload)
            }"
        :disabled="(() => disabledFields(key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :rules="(() => customValidationRules(rules, key))()"
        :save-user-value="false"
      />
      <InputRegionDadata
        v-else-if="key === 'region' && canEdit"
        :label="label"
        :value="value[key]"
        @input="val => onChange(val, key)"
        @select="({ region_iso_code }) => {
              const payload = value;
              set(payload, 'regionIsoCode', region_iso_code);
              $emit('input', payload)
            }"
        :locations="[pick(locations, 'country_iso_code')]"
        :disabled="(() => disabledFields(key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :rules="(() => customValidationRules(rules, key))()"
        :save-user-value="isForeignCompany"
      />
      <InputCityDadata
        v-else-if="key === 'city' && canEdit"
        :label="label"
        :value="value[key]"
        @input="val => onChange(val, key)"
        :locations="[pick(locations, 'country_iso_code', 'region_iso_code')]"
        :disabled="(() => disabledFields(key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :rules="(() => customValidationRules(rules, key))()"
        :save-user-value="isForeignCompany"
      />
      <InputStreetDadata
        v-else-if="key === 'street' && canEdit"
        :label="label"
        :value="value[key]"
        @input="val => onChange(val, key)"
        :locations="[pick(locations, 'country_iso_code', 'region_iso_code')]"
        :disabled="(() => disabledFields(key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :rules="(() => customValidationRules(rules, key))()"
        :save-user-value="isForeignCompany"
      />
      <AccountApplicationField
        v-else-if="isShow(key)"
        :value="value[key]"
        @input="val => onChange(val, key)"
        :label="label"
        :can-edit="canEdit"
        :rules="(() => customValidationRules(rules, key))()"
        :readonly="notEditableFields.includes(key) && isEdit"
        :disabled="(() => disabledFields(key))()"
      />
    </VCol>
  </VRow>
</template>
<script>
import AccountApplicationField from '@/components/account-application/components/AccountApplicationField.vue';
import InputInnDadata from '@/components/general/InputInnDadata/InputInnDadata.vue';
import {get, pick, set} from 'lodash-es';
import {ACCOUNT_APPLICATION_ORGANIZATION_TYPES} from '@/store/account-application/enums';
import InputCountryDadata from '@/components/general/InputCountryDadata/InputCountryDadata.vue';
import InputRegionDadata from '@/components/general/InputRegionDadata/InputRegionDadata.vue';
import InputCityDadata from '@/components/general/InputCityDadata/InputCityDadata.vue';
import InputStreetDadata from '@/components/general/InputStreetDadata/InputStreetDadata.vue';

export default {
  name: 'AccountApplicationAddress',
  components: { InputStreetDadata, InputCityDadata, InputRegionDadata, InputCountryDadata, InputInnDadata, AccountApplicationField },
  props: {
    canEdit: { type: Boolean }, // Права пользователя на редактирования
    isEdit: { type: Boolean }, // Статус заявки
    value: { type: Object },
    organizationType: { type: String }
  },
  data() {
    const rules = {
      required: [(v) => !!v || 'Обязательное поле'],
      inn: [
        (value) => /^\d{10}(\d{2})?$/.test(value) || 'Укажите корректный ИНН',
      ],
      innCompany: [
        (value) => /^\d{10}$/.test(value) || 'Укажите корректный ИНН',
      ],
      innIndividual: [
        (value) => /^\d{12}$/.test(value) || 'Укажите корректный ИНН',
      ],
      kpp: [
        (value) => /^\d{9}(\d{2})?$/.test(value) || 'Укажите корректный КПП',
      ],
    }

    return {
      rules,
      notEditableFields: ['inn', 'kpp'],
      fields: [
        {
          label: 'Наименование (как в Уставе)',
          key: 'companyNameFull',
          rules: rules.required
        },
        {
          label: 'Краткое наименование (как в Уставе)',
          key: 'companyNameShort',
          rules: rules.required
        },
        {
          label: 'ИНН Организации',
          key: 'inn',
          rules: [...rules.required, ...rules.inn]
        },
        {
          label: 'TAX ID',
          key: 'taxId',
          rules: rules.required,
        },
        {
          label: 'КПП Организации',
          key: 'kpp',
          rules: [...rules.required, ...rules.kpp]
        },
        {
          label: 'Страна (Юр. адрес)',
          key: 'country',
          rules: rules.required
        },
        {
          label: 'Регион (Юр. адрес)',
          key: 'region',
          rules: rules.required
        },
        {
          label: 'Индекс (Юр. адрес)',
          key: 'zipCode',
          rules: rules.required
        },
        {
          label: 'Населённый пункт (Юр. адрес)',
          key: 'city',
          rules: rules.required
        },
        {
          label: 'Улица (Юр. адрес)',
          key: 'street',
          rules: rules.required
        },
        {
          label: 'Дом (Юр. адрес)',
          key: 'building',
          rules: rules.required
        },
        {
          label: 'Корпус/Строение (Юр. адрес)',
          key: 'house',
        },
        {
          label: 'Кв/помещение (Юр. адрес)',
          key: 'flat',
        },
      ],
    }
  },
  computed: {
    locations() {
      return {
        country_iso_code: get(this.value, 'countryIsoCode'),
        region_iso_code: get(this.value, 'regionIsoCode')
      }
    },
    isForeignCompany() {
      return this.organizationType === ACCOUNT_APPLICATION_ORGANIZATION_TYPES['FOREIGN_COMPANY'].value;
    }
  },
  methods: {
    pick,
    set,
    onChange(value, key) {
      const payload = this.value;
      set(payload, key, value);
      this.$emit('input', payload)
    },
    disabledFields(key) {
      if (key === 'kpp') {
        const noKpp = get(this.value, 'inn', '').length >= 12;
        if (noKpp) set(this.value, 'kpp', '');
        return noKpp || !this.organizationType;
      }
      if (key === 'country') {
        return !this.isForeignCompany;
      }
      return !get(this.value, 'type');
    },
    customValidationRules(fieldRules, key) {
      if (this.disabledFields(key)) return [];
      if (key === 'kpp' && this.isForeignCompany) return this.rules.kpp;
      if (key === 'inn') {
        if (this.isForeignCompany) return [];
        if (this.organizationType === ACCOUNT_APPLICATION_ORGANIZATION_TYPES['COMPANY'].value) return [...this.rules.required, ...this.rules.innCompany];
        if (this.organizationType === ACCOUNT_APPLICATION_ORGANIZATION_TYPES['INDIVIDUAL_ENTREPRENEUR'].value) return [...this.rules.required, ...this.rules.innIndividual];
      }
      if (key === 'region' && this.isForeignCompany) return [];
      if (key === 'zipCode' && this.isForeignCompany) return [];
      if (key === 'street' && this.isForeignCompany) return [];
      return fieldRules;
    },
    isShow(key) {
      if (key === 'kpp') {
        if (this.organizationType === ACCOUNT_APPLICATION_ORGANIZATION_TYPES['INDIVIDUAL_ENTREPRENEUR'].value) return false
        return true;
      }
      if (key === 'taxId') {
        if (this.isForeignCompany) return true;
        return false
      }
      return true
    },
    updateInnData(item) {
      const payload = this.value;

      payload['kpp'] = get(item, 'data.kpp');
      if (this.organizationType && this.isForeignCompany) {
        payload['country'] = get(item, 'data.address.data.country');
        payload['countryIsoCode'] = get(item, 'data.address.data.country_iso_code');
      }

      payload['region'] = get(item, 'data.address.data.region_with_type');
      payload['regionIsoCode'] = get(item, 'data.address.data.region_iso_code');
      payload['zipCode'] = get(item, 'data.address.data.postal_code');
      payload['city'] = get(item, 'data.address.data.city_with_type');
      payload['street'] = get(item, 'data.address.data.street');
      payload['building'] = get(item, 'data.address.data.house');
      payload['house'] = get(item, 'data.address.data.block_type');
      payload['flat'] = get(item, 'data.address.data.flat');
      this.$emit('input', payload)
    },
  },
  watch: {
    organizationType: {
      immediate: false,
      handler: async function () {
        const payload = this.value;
        if (this.organizationType && !this.isForeignCompany) {
          payload['country'] = 'Россия';
          payload['countryIsoCode'] = 'RU'
        }
        this.$emit('input', payload)
      }
    },
  }
};
</script>
