<template>
  <div>
    <SectionCard class="mb-0" :loading="pending">
      <div :class="$style.header">
        <div :class="$style.title" v-if="isDetail">Пользователь подрядной организации #{{ get(this.detail, 'user.id') }}</div>
        <div :class="$style.title" v-else>Создание пользователя подрядной организации</div>
        <div :class="[$style.status, statusClass]">{{ get(currentStatus, 'text') }}</div>
      </div>
      <VForm v-model="formValidity" ref="form" :disabled="!this.canEdit">
        <VRow>
          <VCol cols="12" sm="3" v-for="{ label, key, rules, mask, type } in defaultFields" :key="key">
            <template v-if="isDetail">
              <StaticField :label="label" :value="payload[key]"/>
            </template>
            <template v-else>
              <DateInput
                v-if="type === 'date'"
                type="DATE"
                :label="label"
                :value="payload[key]"
                :rules="rules"
                :max="new Date().toISOString().slice(0, 10)"
                @input="value => payload[key] = value"
              />
              <VTextField
                v-else
                v-model="payload[key]"
                v-mask="mask"
                :label="label"
                outlined
                dense
                hideDetails="auto"
                :rules="rules"
              />
            </template>
          </VCol>
        </VRow>
        <div class="d-flex align-items-center my-5">
          <VCheckbox outlined dense hide-details label="Пользователь активен" class="d-inline-flex my-0" v-model="payload.isActive" :disabled="!isConfirmed" />
          <VTooltip
            v-if="!isConfirmed"
            class="ms-3"
            right
            max-width="400"
            location-strategy="connected"
          >
            <template v-slot:activator="{ on, attrs }">
              <VIcon class="primary--text" v-bind="attrs" v-on="on">mdi-information-outline</VIcon>
            </template>
            Требуется подтверждение почты
          </VTooltip>
        </div>
        <div :class="$style.alert">Роли пользователя</div>
        <div :class="$style.list">
          <template v-for="(item, index) in roles">
            <div v-if="!isEmpty(get(item, 'roles'))" :class="$style.item" :key="index">
              <h3 class="mb-5">{{ get(item, 'sectionName') }}</h3>
              <VRow no-gutters v-if="!isEmpty(get(item, 'roles'))">
                <VCol cols="12" v-for="role in get(item, 'roles')" :key="get(role, 'id')">
                  <VCheckbox
                    class="d-inline-flex" outlined dense hide-details
                    :label="get(role, 'name')"
                    :value="get(role, 'id') in payload.selectedRole"
                    @change="(val) => onChange(val, get(role, 'id'))"
                  />
                </VCol>
              </VRow>
            </div>
          </template>
        </div>
      </VForm>
    </SectionCard>
    <VFooter app>
      <div class="py-3 grow">
        <VBtn class="mr-6" outlined depressed @click="onBack" :disabled="pending">
          <VIcon>mdi-arrow-left</VIcon>
          Назад к списку
        </VBtn>
        <template v-if="!isNotInvited">
          <template v-if="isDetail">
            <VBtn color="primary" class="mr-2" depressed :disabled="!formValidity || pending || !diff" @click="onSave">Сохранить</VBtn>
            <VBtn color="primary" class="mr-2" depressed :disabled="!diff || pending" @click="onReset">Отменить</VBtn>
          </template>
          <template v-else>
            <VBtn color="primary" class="mr-2" :disabled="!formValidity || pending" depressed @click="onCreate">Создать</VBtn>
          </template>
        </template>
        <template v-if="isNotInvited && isDetail">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onInviteReject">Отменить</VBtn>
          <VBtn color="error" class="mr-2" depressed :disabled="pending" @click="onInviteBlock">Заблокировать</VBtn>
          <VBtn color="success" class="mr-2 float-right" depressed :disabled="pending" @click="onInviteActive">Согласовать</VBtn>
        </template>
      </div>
    </VFooter>
  </div>
</template>
<script>
import SectionCard from '@/components/user/SectionCard/SectionCard.vue';
import DateInput from '@/components/general/DateInput/DateInput.vue';
import {mapActions, mapGetters} from 'vuex';
import {get, isEmpty, flatMap, map, includes, toString, filter, keyBy} from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField.vue';
import {ACCOUNT_USERS_STATUSES, ACCOUNT_USERS_DECISION} from '@/store/account-users/enums';
import {ACCOUNT_APPLICATION_TYPES} from '@/store/account-application/enums';

export default {
  name: 'AccountUsersApplication',
  components: { StaticField, DateInput, SectionCard },
  props: {
    id: { type: String },
  },
  data() {
    const rules = {
      required: [(v) => !!v || 'Обязательное поле'],
      phone: [
        (value) => /[+7] \([\d]{3}\) [\d]{3} [\d]{2} [\d]{2}/.test(value) || 'Укажите корректный телефон',
      ],
      email: [
        (value) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value) || 'Укажите корректный email',
      ],
      passport: [
        (value) => /[\d]{4} [\d]{6}/.test(value) || 'Укажите корректную серию и номер паспорта',
      ]
    }

    return {
      formValidity: false,
      rules,
      payload: this.getPayload(),
      defaultFields: [
        {
          label: 'Фамилия',
          key: 'name',
          rules: rules.required
        },
        {
          label: 'Имя',
          key: 'lastName',
          rules: rules.required
        },
        {
          label: 'Отчество',
          key: 'secondName',
          rules: rules.required
        },
        {
          label: 'Телефон',
          key: 'phone',
          mask: '+7 (###) ### ## ##',
          rules: [...rules.required, ...rules.phone]
        },
        {
          label: 'Email',
          key: 'email',
          rules: [...rules.required, ...rules.email]
        },
        {
          label: 'Должность',
          key: 'position',
          rules: rules.required
        },
        {
          label: 'Серия и номер паспорта',
          key: 'passportNumber',
          mask: '#### ######',
          rules: [...rules.required, ...rules.passport]
        },
        {
          label: 'Дата выдачи',
          key: 'passportDate',
          type: 'date',
          rules: rules.required
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      current: 'user/current',
      detail: 'accountUsers/detail',
      roles: 'accountUsers/roles',
      hasStatus: 'accountUsers/hasStatus',
      pending: 'accountUsers/pending',
      hasRole: 'user/hasRole',
    }),
    isDetail() {
      return !!this.id;
    },
    isConfirmed() {
      return !!get(this.detail, 'user.dateConfirm');
    },
    isNotInvited() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'INVITE_WAITING.value'));
    },
    isNotConfirmed() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'NOT_CONFIRMED.value'));
    },
    isActive() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'ACTIVE.value'));
    },
    isInActive() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'INACTIVE.value'));
    },
    isInviteRejected() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'INVITE_REJECTED.value'));
    },
    isInviteBlocked() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'INVITE_BLOCKED.value'));
    },
    isRejected() {
      return this.hasStatus(get(ACCOUNT_USERS_STATUSES, 'REJECTED.value'));
    },
    canEdit() {
      return !this.pending && (!this.isDetail || this.isInActive || this.isActive) && this.hasRole([get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_ADMINISTRATOR.value'), get(ACCOUNT_APPLICATION_TYPES, 'INITIATOR.value')], 'account');
    },
    currentStatus() {
      return get(ACCOUNT_USERS_STATUSES, [get(this.detail, 'status.code')])
    },
    statusClass() {
      return {
        'green lighten-4': this.isActive,
        'orange lighten-4': this.isNotInvited || this.isNotConfirmed,
        'error lighten-4': this.isInActive || this.isInviteRejected || this.isInviteBlocked || this.isRejected,
      };
    },
    diff() {
      return JSON.stringify(this.getPayload()) !== JSON.stringify(this.payload);
    },
  },
  methods: {
    includes,
    isEmpty,
    get,
    toString,
    ...mapActions({
      fetchAccountUsersDetail: 'accountUsers/getAccountUsersDetail',
      fetchAccountUsersRoles: 'accountUsers/getAccountUsersRoles',
      createAccountUsers: 'accountUsers/createUsersAccount',
      updateAccountUsers: 'accountUsers/updateAccountUsers',
      inviteDecision: 'accountUsers/inviteDecision',
    }),
    async update() {
      if (this.isDetail) await this.fetchAccountUsersDetail({ id: this.id })
      else await this.fetchAccountUsersDetail();

      await this.fetchAccountUsersRoles();
      this.payload = this.getPayload();
    },
    onChange(val, id) {
      if (val) this.$set(this.payload.selectedRole, id, id);
      else this.$delete(this.payload.selectedRole, id)
    },
    async onCreate() {
      const result = await this.createAccountUsers({
        accountId: get(this.current, 'account.accountId'),
        fields: {
          user: {
            name: toString(get(this.payload, 'name')),
            lastName: toString(get(this.payload, 'lastName')),
            secondName: toString(get(this.payload, 'secondName')),
            email: toString(get(this.payload, 'email')),
            phone: toString(get(this.payload, 'phone')),
            passportNumber: toString(get(this.payload, 'passportNumber')),
            passportDate: toString(get(this.payload, 'passportDate')),
            position: toString(get(this.payload, 'position')),
            isActive: get(this.payload, 'isActive'),
          },
          roles: flatMap(this.roles, (section) => filter(get(section, 'roles'), (role) => get(role, 'id') in this.payload.selectedRole)),
        },
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Пользователь успешно создан',
        });

        const id = get(result, 'id');
        this.$router.push({ name: 'account-users/AccountUsersApplicationView', params: { application: id }});
      }
    },
    async onSave() {
      const result = await this.updateAccountUsers({
        id: get(this.detail, 'id'),
        fields: {
          user: {
            name: toString(get(this.payload, 'name')),
            lastName: toString(get(this.payload, 'lastName')),
            secondName: toString(get(this.payload, 'secondName')),
            email: toString(get(this.payload, 'email')),
            phone: toString(get(this.payload, 'phone')),
            passportNumber: toString(get(this.payload, 'passportNumber')),
            passportDate: toString(get(this.payload, 'passportDate')),
            position: toString(get(this.payload, 'position')),
            isActive: get(this.payload, 'isActive'),
          },
          roles: flatMap(this.roles, (section) => filter(get(section, 'roles'), (role) => get(role, 'id') in this.payload.selectedRole)),
        },
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Пользователь успешно изменён',
        });
      }

      await this.update();
    },
    onReset() {
      this.$refs.form?.resetValidation();
      this.payload = this.getPayload();
    },
    async onInviteReject() {
      const result = await this.inviteDecision({ userId: get(this.detail, 'user.id'), accountId: get(this.detail, 'accountId'), decision: get(ACCOUNT_USERS_DECISION, 'REJECT') })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отклонена',
        });
        this.update();
      }
    },
    async onInviteBlock() {
      const result = await this.inviteDecision({ userId: get(this.detail, 'user.id'), accountId: get(this.detail, 'accountId'), decision: get(ACCOUNT_USERS_DECISION, 'BLOCK') })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Пользователь успешно заблокирован',
        });
        this.update();
      }
    },
    async onInviteActive() {
      const result = await this.inviteDecision({ userId: get(this.detail, 'user.id'), accountId: get(this.detail, 'accountId'), decision: get(ACCOUNT_USERS_DECISION, 'ACTIVE') })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно согласована',
        });
        this.update();
      }
    },
    async onBack() {
      if (this.diff) {
        const { isConfirmed } = await this.$swal("Вы уверены что хотите вернуться? Не сохранённые данные будут утеряны");
        if (!isConfirmed) return;
      }
      if (this.isNotInvited) this.$router.push({ name: 'account-users/AccountUsersView', query: { tab: 'users-invite' }})
      else this.$router.push({ name: 'account-users/AccountUsersView'})
    },
    getPayload() {
      return {
        isActive: get(this.detail, 'user.isActive'),
        name: get(this.detail, 'user.name'),
        lastName: get(this.detail, 'user.lastName'),
        secondName: get(this.detail, 'user.secondName'),
        email: get(this.detail, 'user.email'),
        phone: get(this.detail, 'user.phone'),
        passportNumber: get(this.detail, 'user.passportNumber'),
        passportDate: get(this.detail, 'user.passportDate'),
        position: get(this.detail, 'user.position'),
        roles: get(this.detail, 'roles'),
        selectedRole: keyBy(map(get(this.detail, 'roles'), (role) => get(role, 'id'))),
      };
    },
  },
  beforeMount() {
    this.update();
  }
}
</script>
<style module lang="scss">
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 16px;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.87);
}

.status {
  padding: 4px 8px;
  text-transform: uppercase;
  line-height: 2;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  white-space: nowrap;

  &:empty { display: none; }
}

.alert {
  font-weight: 500;
  font-size: 18px;
  background: #fff7e4;
  padding: 8px 16px;
  margin: 0 -16px;
}

.list {

}

.item {
  padding: 20px;
  margin: 0 -16px;
  border-top: 1px solid #dee2e6;
}
</style>
